<!-- 会员管理 -->
<template>
  <div>
    <div class="mainBody">
      <!--搜索框-->
      <div class="filter-bar-user-post">
        <!--id-->
        <div>
          <label for="contactName">Id ：</label>
          <input
            type="text"
            id="contactName"
            v-model.trim="id"
          >
        </div>
        <!--业务类型-->
        <!-- <div class="product-type" v-if="false">
          <label>{{ $t('ProductType') }}：</label>
          <ProductTypes
            class="selector"
            v-if="productTypeDialog"
            @getType="getProductType"
          ></ProductTypes>
        </div> -->
        <!--创建时间-->
        <div>
          <label>{{ $t('CreateTime') }}：</label>
          <el-date-picker
            v-model="searchDate"
            :placeholder="$t('ChooseDate')"
            value-format="yyyy-MM-dd"
            style="float: left;"
          >
          </el-date-picker>
        </div>
        <el-button @click="searchUserData">{{ $t('Search') }}</el-button>
      </div>

      <!--二级导航栏-->
      <div class="navigatorLawyer">
        <span
          style="border-right:none"
          @click="active(3,'active1')"
          :class="{'active':active1===3}"
        >{{ $t('AllData') }}</span>
        <span
          style="border-right:none"
          @click="active(2,'active1')"
          :class="{'active':active1===2}"
        >{{ $t('CommerceCoop') }}</span>
        <span
          style="border-right:none"
          @click="active(0,'active1')"
          :class="{'active':active1===0}"
        >{{ $t('Buyers') }}</span>
        <span
          @click="active(1,'active1')"
          :class="{'active':active1===1}"
        >{{ $t('Sellers') }}</span>
        <span
          style="border-right:none"
          @click="active(1,'active2')"
          :class="{'active':active2===1}"
        >{{ $t('AllData') }}</span>
        <span
          style="border-right:none"
          @click="active(2,'active2')"
          :class="{'active':active2===2}"
        >{{ $t('Auditing') }}</span>
        <span
          style="border-right:none"
          @click="active(0,'active2')"
          :class="{'active':active2===0}"
        >{{ $t('Passed') }}</span>
        <span
          @click="active(3,'active2')"
          :class="{'active':active2===3}"
        >{{ $t('ManRefused') }}</span>
      </div>

      <!--表格-->
      <el-table
        :data="userList"
        border
        :header-cell-style="{background:'#CCCCCC',color:'black'}"
        style="width: 99%;margin: 0 auto"
      >
        <!--id-->
        <el-table-column
          fixed
          prop="postId"
          width="200"
          label="Id"
          align="center"
        >
        </el-table-column>
        <!--帖子标题-->
        <el-table-column
          prop="postTitle"
          :formatter="formatTitle"
          :label="$t('Title')"
          align="center"
        >
        </el-table-column>
        <!--帖子类型-->
        <el-table-column :label="$t('PostType')">
          <template slot-scope="scope">{{ threePostType(scope.row.postType) }}</template>
        </el-table-column>
        <!--组别-->
        <el-table-column :label="$t('GroupType')" prop="groupName">
          <!--<template slot-scope="scope">{{ scope.row.groupName }}</template>-->
        </el-table-column>
        <!--创建时间-->
        <el-table-column
          prop="createTime"
          width="180"
          align="center"
          :label="$t('CreateTime')"
        >
        </el-table-column>
        <!--审核时间-->
        <el-table-column
          prop="reviewTime"
          width="180"
          align="center"
          :label="$t('reviewTime')"
        >
        </el-table-column>
        <!--补充-->
        <el-table-column
          prop="additional"
          align="center"
          :label="$t('AdditionInfo')"
        >
        </el-table-column>
        <!--状态-->
        <el-table-column
          width="130"
          align="center"
          :label="$t('Status')"
        >
          <template slot-scope="scope">
            <span :class="{
                  'autopass-text': scope.row.status === 0,
                  'manpass-text': scope.row.status === 1,
                  'sensitive-text': scope.row.status === 2,
                  'refuse-text': scope.row.status === 3 || scope.row.status === 4
                }">
              {{ numToStatusUPost(scope.row.status) }}
            </span>
          </template>
        </el-table-column>
        <!--操作-->
        <el-table-column
          prop="operation"
          min-width="90"
          class="operation-zone"
          align="center"
          :label="$t('Operation')"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="toPostDetail(scope.row)"
              class="detail-button"
            >
              {{ $t('Detail') }}
            </el-button>
            <!--待审核 通过-->
            <el-button
              type="text"
              size="small"
              @click="passPost(scope.row,0)"
              v-if="scope.row.status === 2"
            >
              {{ $t('Pass') }}
            </el-button>
            <!--已通过 全系统撤回-->
            <el-button
              type="text"
              size="small"
              @click="deletePost(scope.row)"
              v-if=" scope.row.status === 0||scope.row.status === 1"
              style="color: red"
            >
              {{ $t('RevokeAll') }}
            </el-button>
            <!--待审核 拒绝-->
            <el-button
              type="text"
              size="small"
              @click="refusePost(scope.row);"
              v-if=" scope.row.status === 2"
              style="color: red"
            >
              {{ $t('Refuse') }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <!--分页-->
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        layout="prev, pager, next"
        :page-size="pageSize"
        :total="userTotal"
        style="margin: 10px auto 10px 20px"
      >
      </el-pagination>
    </div>

    <!--拒绝帖子弹窗-->
    <RefuseDialog
      v-if="refuseDialog"
      :refuseDialogFlag="refuseDialog"
      :refuseDialogParam="refuseDialogParam"
      @closeRefuse="closeRefuseDialog"
    ></RefuseDialog>
  </div>
</template>

<script>
import api from "@/fetch/api";
import RefuseDialog from "@/views/PostMenu/RefuseDialog.vue";
import ProductTypes from "@/common/ProductTypes.vue";
import mixin from "@/assets/js/mixin";

export default {
  mixins: [mixin],
  components: {
    RefuseDialog,
    ProductTypes,
  },
  data() {
    //            读取本地个人信息
    const data = JSON.parse(localStorage.getItem("cacheChamber")).adminData;
    return {
      refuseDialog: false,
      productTypeDialog: true,
      firmId: data.firmId,
      adminId: data.id,
      active1: 3,
      active2: 1,
      userList: [],
      userTotal: 0,
      pageSize: 10,
      currentPage: 1,
      myRight: Number(data.postPermission),

      //                搜索用
      id: "",
      productTypes: [],
      searchDate: "",

      initPost: {
        chamberId: data.chamberId,
        page: 1,
        currentAdmin: data.id,
      },

      // 拒绝弹窗
      refuseDialogParam: {},
      param: {},
    };
  },
  mounted() {
    this.initPostData(1);
  },
  methods: {
    //    postType          0:买家  1：卖家  2：合作伙伴  3：全部
    //    status          1:全部  0:通过   2：待审核   3：已拒绝  4:已过期
    active(num, tab) {
      this[tab] = num;
      this.initPostData(1);
    },

    //            初始化列表
    initPostData(currentPage) {
      const locale = sessionStorage.getItem("locale");
      const languageObj = {
        "zh-CN": "Chinese",
        "en-US": "English",
        "bahasa-Indonesia": "Bahasa",
        "original-text": "non",
      };
      // console.log('this.active1,this.active2 ->',this.active1,this.active2)
      this.currentPage = currentPage;
      this.initPost.page = currentPage;
      this.active1 === 3
        ? delete this.initPost.postType
        : (this.initPost.postType = this.active1);
      this.active2 === 1
        ? delete this.initPost.status
        : (this.initPost.status = this.active2);
      // 搜索用
      this.id ? (this.initPost.postId = this.id) : delete this.initPost.postId;
      this.productTypes && this.productTypes.length
        ? (this.initPost.productTypes = this.productTypes)
        : delete this.initPost.productTypes;
      this.searchDate
        ? (this.initPost.createTime = this.searchDate)
        : delete this.initPost.createTime;
      this.initPost.language = languageObj[locale];
      api.getMembershipPosts(this.initPost).then((res) => {
        this.userList = res.data.pageList;
        this.userTotal = res.data.total;
      });
    },

    //            分页
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.initPostData(currentPage);
    },

    //            搜索用户列表
    searchUserData() {
      this.id ? (this.initPost.postId = this.id) : delete this.initPost.postId;
      this.postType
        ? (this.initPost.postType = this.postType)
        : delete this.initPost.postType;
      this.productTypes
        ? (this.initPost.productTypes = this.productTypes)
        : delete this.initPost.productTypes;
      this.searchDate
        ? (this.initPost.createTime = this.searchDate)
        : delete this.initPost.createTime;
      // console.log('api调用', this.initPost)
      this.currentPage = 1;
      this.initPostData(1);
    },

    // 帖子状态
    numToStatusUPost(status) {
      switch (status) {
        case 0:
          return this.$t("AutoPass");
        case 1:
          return this.$t("ManPass");
        case 2:
          return this.$t("Sensitive");
        case 3:
          return this.$t("ManRefused");
        case 4:
          return this.$t("AutoRefused");
      }
    },

    //     展示选中产品类型
    getProductType(val) {
      // console.log('val ->',val[0])
      this.productTypes = val[0];
    },

    //            -------------------------------------------操作区-------------------------------------

    //            查看详情
    toPostDetail(tab) {
      // console.log('tab.postOwnerId ->',tab.postOwnerId)
      this.$router.push({
        name: "DetailedInformation",
        query: {
          id: tab.postId,
          postType: tab.postType, // 0 买家  1 卖家  2合作伙伴
          isUser: 1, // 0 从接收帖子来   1 从成员帖子传过来   2 从举报帖子来
          routerName: "UserPost",
          postOwnerId: tab.postOwnerId,
        },
      });
    },

    //            通过帖子
    passPost(tab, action) {
      if(this.myRight<2){
        this.$message.error(this.$t('NoRight'))
      }else{
        api
            .getReviewPost({
              currentAdmin: this.adminId,
              postId: tab.postId || tab.partnerId,
              postType: tab.postType === 2 ? 1 : 0, //  0 买家/卖家   1 合作伙伴
              action, // 0：通过  1拒绝
            })
            .then((res) => {
              // console.log('res.msg', res.msg);
              this.initPostData(this.currentPage);
            });
      }
    },

    // 全系统删除帖子
    deletePost(row) {
      if(this.myRight<3){
        this.$message.error(this.$t('NoRight'))
      }else{
        this.$confirm(this.$t("ConfirmDelete"), this.$t("Attention"), {
          confirmButtonText: this.$t("Confirm"),
          cancelButtonText: this.$t("Cancle"),
          type: "warning",
          dangerouslyUseHTMLString: true,
        })
            .then(() => {
              api
                  .getDeleteById({
                    currentAdmin: this.adminId,
                    barId: row.postId,
                    type: 2, // 1:本系统删除、2:全系统删除
                  })
                  .then(() => {
                    this.initPostData(this.currentPage);
                  });
            })
            .catch(() => {});
      }
    },

    //            拒绝帖子
    refusePost(tab) {
      if(this.myRight<2){
        this.$message.error(this.$t('NoRight'))
      }else{
        this.refuseDialog = true;
        let param = {
          currentAdmin: this.adminId,
        };
        if (tab.postType === 2) {
          // 合作伙伴
          param.partnerId = tab.postId;
          api.getBackEndPartnerDetail(param).then((res) => {
            this.refuseDialogParam = res.data;
            this.refuseDialogParam.postType = 1;
          });
        } else {
          // 买家卖家
          param.postId = tab.postId;
          api.getBackEndPostDetail(param).then((res) => {
            this.refuseDialogParam = res.data;
            this.refuseDialogParam.postType = 1;
          });
        }
        this.refuseDialogParam.isReceive = false; // 成员帖子， isReceive 传 false
      }
    },

    //   关闭弹窗
    closeRefuseDialog(val) {
      this.refuseDialog = val;
      this.initPostData(this.currentPage);
    },
  },
};
</script>
<style lang="scss" scoped>
.import {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
}

.mainBody {
  width: 1600px;
  margin-top: 5px;
}

.filter-bar-user-post {
  width: 99%;
  height: 50px;
  margin: 5px auto 10px auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  background-color: #677d95;
  border-radius: 5px;
  color: white;
  padding-top: 5px;

  input {
    min-width: 200px;
    border: 1px solid white;
    margin: 8px 0;
    float: left;
  }

  div label {
    margin: 8px 0 8px 10px;
    display: block;
    float: left;
    width: fit-content;
  }

  .el-button {
    margin: 5px 10px;
    width: 200px;
    height: 30px;
    line-height: 0.5px;
    background-color: #677d95;
    color: white;

    &:hover {
      background-color: #9fbee6 !important;
    }
  }

  .product-type {
    .selector {
      width: 200px;
    }
    ::v-deep.el-cascader-panel {
      display: flex;
      border-radius: 4px;
      font-size: 14px;
    }

    .el-cascader-menu:last-child .el-cascader-node {
      padding-right: 20px;
    }

    ::v-deep.el-cascader-menu__list {
      position: relative;
      min-height: 100%;
      margin: 0;
      padding: 6px 0;
      list-style: none;
      box-sizing: border-box;
    }

    ::v-deep.el-cascader {
      min-width: 200px;
      margin-right: 20px;
    }

    ::v-deep.el-scrollbar__wrap {
      overflow: hidden;
      margin-bottom: 0 !important;
      margin-right: 0 !important;
    }
  }
}

.navigatorLawyer {
  width: 99%;
  height: 80px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-row-gap: 5px;
  /*border:1px solid black;*/
  margin: 10px auto;
  //background-color: #425D7B;
  background-color: white;

  & > span {
    padding-top: 3px;
    text-align: center;
    border: 1px solid black;
    //background-color: #425D7B;
    line-height: 30px;
    cursor: pointer;
  }
}

.el-table th {
  background-color: #677d95;
  color: white;
  opacity: 1;
}
/*::v-deep .el-table td {
  text-align: center!important;
}*/

/*.detail-button{
  display: block;
  margin: 0 auto;
}*/
.sensitive-text {
  color: red;
}
.autopass-text {
  color: #d4b966;
}
.manpass-text {
  color: #85b32b;
}
.refuse-text {
  color: black;
}

.active {
  //background-color: #1C252C;
  background-color: #677d95;
  color: white;
}

.table {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

/*input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.export-excel-wrapper {
  float: left;
}*/
</style>
<!--<style lang="scss">
.el-cascader-panel{
  display: flex;
  border-radius: 4px;
  font-size: 14px;
}
.el-cascader-menu {
  min-width: 180px;
  box-sizing: border-box;
  color: #606266;
  border-right: solid 1px #E4E7ED;
}

.el-cascader-menu:last-child .el-cascader-node {
  padding-right: 20px;
}

.el-cascader-menu__list {
  position: relative;
  min-height: 100%;
  margin: 0;
  padding: 6px 0;
  list-style: none;
  box-sizing: border-box;
}

.el-cascader{
  min-width: 200px;
  margin-right: 20px;
}
.el-scrollbar {
  overflow: hidden;
  position: relative;
}

.el-scrollbar__wrap {
  margin-bottom: 0 !important;
  margin-right:  0 !important;
}
::-webkit-scrollbar { /* Chrome、Safari 和 Opera */
  display: none;
}
.el-cascader-node {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 30px 0 20px;
  //height: 34px;
  //line-height: 34px;
  outline: 0;
}
.el-cascader-node__label {
  flex: 1;
  padding: 0 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.el-checkbox {
  color: #606266;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  margin-right: 0;
}
.el-cascader__tags {
  position: absolute;
  left: 0;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-wrap: wrap;
  line-height: normal;
  text-align: left;
  box-sizing: border-box;
}
.el-cascader__tags .el-tag {
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  margin: 2px 0 2px 6px;
  text-overflow: ellipsis;
  background: #f0f2f5;
}
.el-cascader-node.in-active-path, .el-cascader-node.is-active, .el-cascader-node.is-selectable.in-checked-path {
  color: #409EFF;
  font-weight: 700;
}
.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #409EFF;
  border-color: #409EFF;
}
</style>-->


